import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import commonUs from './app/locales/us/common.json'
import commonAz from './app/locales/az/common.json'
import commonTr from './app/locales/tr/common.json'

const resources = {
    us: {common: commonUs},
    az: {common: commonAz},
    tr: {common: commonTr}
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        ns: ['common'],
        defaultNS: 'common',
        fallbackLng: 'az',
        supportedLngs: ['us', 'tr', 'az'],
        interpolation: {
            escapeValue: false,
        },
        debug: false
    });

export default i18n;
