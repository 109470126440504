import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './pages/shared/Navbar';
import Sidebar from './pages/shared/Sidebar';
import SettingsPanel from './pages/shared/SettingsPanel';
import Footer from './pages/shared/Footer';
import {ToastContainer} from 'react-toastify';
import {withTranslation} from "react-i18next";
import {getData} from "./pages/shared/services/ApiService";

class App extends Component {

    constructor(prop) {
        super(prop);
        this.state = {
            redirectToLogin: false,
            userDetail: {
                id: 0,
                name: '',
                surname: ''
            }
        }
    }
    

    componentWillMount() {
        if (!localStorage.getItem('current_language')) {
            localStorage.setItem('current_language', 'az')
        }
    }

    componentDidMount() {
        getData('user/my').then(response => {
            this.setState({
                userDetail: response.data.user
            });
            this.onRouteChanged();
        }).catch(error => {
            this.props.history.push('/oauth/login');
        });
    }

    render() {
        let navbarComponent = !this.state.isFullPageLayout ? <Navbar userDetail={this.state.userDetail}/> : '';
        let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
        let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel/> : '';
        let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';

        return (
            <div className="container-scroller">
                <ToastContainer position="top-center"
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick={false}
                                pauseOnFocusLoss={false}
                                draggable
                                pauseOnHover={false}/>
                {navbarComponent}
                <div className="container-fluid page-body-wrapper">
                    {sidebarComponent}
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <AppRoutes/>
                            {SettingsPanelComponent}
                        </div>
                        {footerComponent}
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        window.scrollTo(0, 0);
        const fullPageLayoutRoutes = ['/oauth/login', '/oauth/register', '/oauth/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
        for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
            if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
                this.setState({
                    isFullPageLayout: true
                })
                document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
                break;
            } else {
                this.setState({
                    isFullPageLayout: false
                })
                document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
            }
        }
    }

}

export default withTranslation()(withRouter(App));
