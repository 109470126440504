import React, { Component, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Spinner from "./pages/shared/Spinner";

const Dashboard = lazy(() => import("./pages/main/dashboard/Dashboard"));

const LogList = lazy(() => import("./pages/main/logs/LogList"));

const EventList = lazy(() => import("./pages/main/event/EventList"));
const EventForm = lazy(() => import("./pages/main/event/EventForm"));

const BlogList = lazy(() => import("./pages/main/event/BlogList"));
const BlogForm = lazy(() => import("./pages/main/event/BlogForm"));

const MessageList = lazy(() => import("./pages/main/event/MessageList"));
const MessageInfo = lazy(() => import("./pages/main/event/MessageInfo"));

const EventTagList = lazy(() => import("./pages/main/event/TagList"));
const EventTagForm = lazy(() => import("./pages/main/event/TagForm"));

const ContactForm = lazy(() => import("./pages/main/event/ContactForm"));

const SeoForm = lazy(() => import("./pages/main/event/SeoForm"));

const CounterForm = lazy(() => import("./pages/main/event/CounterForm"));

const EventAllRequestList = lazy(() =>
  import("./pages/main/event/EventAllRequestList")
);
const EventRequestList = lazy(() =>
  import("./pages/main/event/EventRequestList")
);
const EventCommentList = lazy(() =>
  import("./pages/main/event/EventCommentList")
);

const AppointmentAllRequestList = lazy(() =>
  import("./pages/main/appointment/AppointmentAllRequestList")
);
const AppointmentRequestList = lazy(() =>
  import("./pages/main/appointment/AppointmentRequestList")
);
const AppointmentList = lazy(() =>
  import("./pages/main/appointment/AppointmentList")
);
const AppointmentForm = lazy(() =>
  import("./pages/main/appointment/AppointmentForm")
);

const UserForm = lazy(() => import("./pages/main/users/UserForm"));
const UserList = lazy(() => import("./pages/main/users/UserList"));
const UserDetail = lazy(() => import("./pages/main/users/UserDetail"));
const UserTitleForm = lazy(() => import("./pages/main/users/UserTitleForm"));
const UserTitleList = lazy(() => import("./pages/main/users/UserTitleList"));

const ScheduleList = lazy(() => import("./pages/main/schedule/ScheduleList"));
const ScheduleForm = lazy(() => import("./pages/main/schedule/ScheduleForm"));

const ScheduleAllRequestList = lazy(() =>
  import("./pages/main/schedule/ScheduleAllRequestList")
);
const ScheduleRequestList = lazy(() =>
  import("./pages/main/schedule/ScheduleRequestList")
);

const Error404 = lazy(() => import("./pages/shared/error-pages/Error404"));
const Error500 = lazy(() => import("./pages/shared/error-pages/Error500"));

const Login = lazy(() => import("./pages/auth/Login"));
const Register1 = lazy(() => import("./pages/auth/Register"));

const PostList = lazy(() => import("./pages/main/users/UserPostList"));

const UserPostDetail = lazy(() => import("./pages/main/users/UserPostDetail"));

const TicketList = lazy(() => import("./pages/main/ticket/TicketList"));
const TicketDetail = lazy(() => import("./pages/main/ticket/TicketDetail"));

// const PagesList = lazy(() => import("./pages/main/pages/PagesList"));
// const PagesForm = lazy(() => import("./pages/main/pages/PagesForm"));

const PagesList = lazy(() => import("./pages/main/event/PageList"));
const PageForm = lazy(() => import("./pages/main/event/PageForm"));

const SliderList = lazy(() => import("./pages/main/event/SliderList"));
const SliderForm = lazy(() => import("./pages/main/event/SliderForm"));

const PartnerList = lazy(() => import("./pages/main/event/PartnerList"));
const PartnerForm = lazy(() => import("./pages/main/event/PartnerForm"));

const ServiceList = lazy(() => import("./pages/main/event/ServiceList"));
const ServiceForm = lazy(() => import("./pages/main/event/ServiceForm"));

const VacancyForm = lazy(() => import("./pages/main/event/VacancyForm"));
const VacancyList = lazy(() => import("./pages/main/event/VacancyList"));

const SectorList = lazy(() => import("./pages/main/event/SectorList"));
const SectorForm = lazy(() => import("./pages/main/event/SectorForm"));

const AmbassadorMessageForm = lazy(() =>
  import("./pages/main/pages/AmbassadorMessageForm")
);

const PromotionsForm = lazy(() =>
  import("./pages/main/promotions/PromotionForm")
);

function requireAuth() {
  let token;
  if (localStorage.getItem("access_token")) {
    token = true;
  } else token = !!sessionStorage.getItem("access_token");
  return token;
}

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            path="/dashboard"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <Dashboard />
            }
          />

          <Route
            path="/ambasador-schedule-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <ScheduleList />
            }
          />
          <Route
            exact
            path="/ambasador-schedule-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <ScheduleForm />
            }
          />
          <Route
            exact
            path="/ambasador-schedule-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <ScheduleForm {...props} />
              )
            }
          />

          <Route
            exact
            path="/ambasador-schedule-request/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <ScheduleRequestList {...props} />
              )
            }
          />

          <Route
            exact
            path="/ambasador-schedule-request-all"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <ScheduleAllRequestList />
              )
            }
          />

          <Route
            path="/event-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <EventList />
            }
          />
          <Route
            exact
            path="/product-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <EventForm />
            }
          />
          <Route
            exact
            path="/product-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <EventForm {...props} />
              )
            }
          />

          <Route
            exact
            path="/seo-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <SeoForm />
            }
          />

          <Route
            exact
            path="/contact-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <ContactForm />
            }
          />

          <Route
            exact
            path="/counter-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <CounterForm />
            }
          />

          <Route
            path="/tag-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <EventTagList />
            }
          />
          <Route
            exact
            path="/tag-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <EventTagForm />
            }
          />
          <Route
            exact
            path="/tag-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <EventTagForm {...props} />
              )
            }
          />

          <Route
            exact
            path="/event-comment/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <EventCommentList {...props} />
              )
            }
          />

          <Route
            exact
            path="/event-request/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <EventRequestList {...props} />
              )
            }
          />

          <Route
            exact
            path="/event-request-list"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <EventAllRequestList {...props} />
              )
            }
          />

          <Route
            path="/appointment-list"
            render={() =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <AppointmentList />
              )
            }
          />
          <Route
            exact
            path="/appointment-form"
            render={() =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <AppointmentForm />
              )
            }
          />
          <Route
            exact
            path="/appointment-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <AppointmentForm {...props} />
              )
            }
          />

          <Route
            exact
            path="/appointment-request/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <AppointmentRequestList {...props} />
              )
            }
          />

          <Route
            exact
            path="/appointment-request-list"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <AppointmentAllRequestList />
              )
            }
          />

          <Route
            exact
            path="/logs"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <LogList />
            }
          />

          <Route
            path="/user-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <UserList />
            }
          />
          <Route
            exact
            path="/user-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <UserForm />
            }
          />

          <Route
            exact
            path="/user-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <UserForm {...props} />
              )
            }
          />

          <Route
            path="/blog-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <BlogList />
            }
          />
          <Route
            exact
            path="/blog-form"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <BlogForm />
            }
          />
          <Route
            exact
            path="/blog-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <BlogForm {...props} />
              )
            }
          />

          <Route
            path="/page-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <PagesList />
            }
          />
          <Route
            exact
            path="/page-form"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <PageForm />
            }
          />
          <Route
            exact
            path="/page-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <PageForm {...props} />
              )
            }
          />

          <Route
            path="/message-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <MessageList />
            }
          />
          <Route
            exact
            path="/message-info/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <MessageInfo {...props} />
              )
            }
          />

          <Route
            path="/slider-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <SliderList />
            }
          />
          <Route
            exact
            path="/slider-form"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <SliderForm />
            }
          />
          <Route
            exact
            path="/slider-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <SliderForm {...props} />
              )
            }
          />

          <Route
            path="/partner-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <PartnerList />
            }
          />
          <Route
            exact
            path="/partner-form"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <PartnerForm />
            }
          />
          <Route
            exact
            path="/partner-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <PartnerForm {...props} />
              )
            }
          />

          <Route
            path="/service-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <ServiceList />
            }
          />
          <Route
            exact
            path="/service-form"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <ServiceForm />
            }
          />
          <Route
            exact
            path="/service-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <ServiceForm {...props} />
              )
            }
          />

          <Route
            path="/vacancy-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <VacancyList />
            }
          />
          <Route
            exact
            path="/vacancy-form"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <VacancyForm />
            }
          />
          <Route
            exact
            path="/vacancy-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <VacancyForm {...props} />
              )
            }
          />

          <Route
            path="/sector-list"
            render={() =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <SectorList />
            }
          />
          <Route
            exact
            path="/sector-form"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <SectorForm />
            }
          />
          <Route
            exact
            path="/sector-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <SectorForm {...props} />
              )
            }
          />

          <Route
            path="/user-title-list"
            render={() =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <UserTitleList />
              )
            }
          />
          <Route
            exact
            path="/user-title-form"
            render={() =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <UserTitleForm />
              )
            }
          />
          <Route
            exact
            path="/user-title-form/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <UserTitleForm {...props} />
              )
            }
          />

          <Route
            exact
            path="/user-detail/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <UserDetail {...props} />
              )
            }
          />

          <Route
            exact
            path="/post-list"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <PostList />
            }
          />

          <Route
            exact
            path="/user-post/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <UserPostDetail {...props} />
              )
            }
          />

          <Route
            exact
            path="/tickets"
            render={(props) =>
              !requireAuth() ? <Redirect to="/oauth/login" /> : <TicketList />
            }
          />

          <Route
            exact
            path="/ticket-detail/:id"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <TicketDetail {...props} />
              )
            }
          />

          <Route
            exact
            path="/promotions"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <PromotionsForm />
              )
            }
          />

          <Route
            exact
            path="/ambassador-message"
            render={(props) =>
              !requireAuth() ? (
                <Redirect to="/oauth/login" />
              ) : (
                <AmbassadorMessageForm />
              )
            }
          />

          <Route exact path="/oauth/login" component={Login} />
          <Route path="/oauth/register" component={Register1} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
          {/*<Redirect to="/oauth/login"/>*/}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
