import axios from 'axios';

export const apiUrl = process.env.REACT_APP_API_URL;

export const login = (url, data) => {
    url = apiUrl + 'user/' + url;
    return axios.post(url, data);
};

export const getData = (url) => {
    let token;
    if (localStorage.getItem('access_token')) {
        token = localStorage.getItem('access_token');
    } else if (sessionStorage.getItem('access_token')) {
        token = sessionStorage.getItem('access_token');
    }
    url = apiUrl + url;
    return axios.get(url, {headers: {'authorization': 'Bearer ' + token}});
};

export const postData = (url, data) => {
    let token;
    if (localStorage.getItem('access_token')) {
        token = localStorage.getItem('access_token');
    } else if (sessionStorage.getItem('access_token')) {
        token = sessionStorage.getItem('access_token');
    }
    url = apiUrl + url;

    return axios.post(url, data, {
        headers: {'authorization': 'Bearer ' + token},
    });
};

export const updateData = (url, data) => {
    let token;
    if (localStorage.getItem('access_token')) {
        token = localStorage.getItem('access_token');
    } else if (sessionStorage.getItem('access_token')) {
        token = sessionStorage.getItem('access_token');
    }
    url = apiUrl + url;
    return axios.patch(url, data, {
        headers: {'authorization': 'Bearer ' + token},
    });
};

export const deleteData = (url) => {
    let token;
    if (localStorage.getItem('access_token')) {
        token = localStorage.getItem('access_token');
    } else if (sessionStorage.getItem('access_token')) {
        token = sessionStorage.getItem('access_token');
    }
    url = apiUrl + url;
    return axios.delete(url, {
        headers: {'authorization': 'Bearer ' + token},
    });
};

export const handleError = async (error) => {
    if (error.response.status === 401) {
        // Actions.loginScreen();
    } else if (error.response.status === 403) {
        // Actions.loginScreen();
    } else {
        // Alert.alert('Xəta!', error.response.data.uimessage[0].text,
        //     [
        //         {
        //             text: 'OK',
        //         }
        //     ],
        //     {
        //         cancelable: false
        //     }
        // );
    }
};
