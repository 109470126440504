import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Collapse} from 'react-bootstrap';
import {Trans} from 'react-i18next';

class Sidebar extends Component {
    state = {};

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({[menuState]: false});
        } else if (Object.keys(this.state).length === 0) {
            this.setState({[menuState]: true});
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({[i]: false});
            });
            this.setState({[menuState]: true});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        document.querySelector('#sidebar').classList.remove('active');
        Object.keys(this.state).forEach(i => {
            this.setState({[i]: false});
        });

        const dropdownPaths = [
            {path: '/event-', state: 'eventMenuOpen'},
            {path: '/blog-', state: 'blogMenuOpen'},
            {path: '/appointment', state: 'appointmentMenuOpen'},
            {path: '/ambasador-', state: 'ambasadorMenuOpen'},
            {path: '/user-', state: 'userMenuOpen'},
            {path: '/post-', state: 'userMenuOpen'},
            {path: '/page-', state: 'pageMenuOpen'},
            {path: '/slider-', state: 'sliderMenuOpen'},
            {path: '/service-', state: 'serviceMenuOpen'},
            {path: '/vacancy-', state: 'vacancyMenuOpen'},
            {path: '/sector-', state: 'sectorMenuOpen'},
            {path: '/partner-', state: 'partnerMenuOpen'},
        ];

        dropdownPaths.forEach((obj => {
            if (this.isPathActive(obj.path)) {
                this.setState({[obj.state]: true})
            }
        }));

    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }

    componentDidMount() {
        this.onRouteChanged();
        const body = document.querySelector('body');
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open');
                }
            });
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open');
                }
            });
        });
    }

    render() {
        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
                    <a className="sidebar-brand brand-logo" href="/dashboard">
                        <img src={require("../../../assets/images/logo.png")} alt="logo" style={{width: "50px"}}/></a>
                    <a className="sidebar-brand brand-logo-mini pt-3" href="/dashboard"><img
                        src={require("../../../assets/images/logo-mini.svg")} alt="logo"/></a>
                </div>
                <ul className="nav">
                    {/* <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/dashboard">
                            <i className="fal fa-tachometer-alt menu-icon"></i>
                            <span className="menu-title"><Trans>dashboard</Trans></span>
                        </Link>
                    </li> */}
                    
                    <li className={this.isPathActive('/tag-list') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/tag-list">
                            <i className="fal fa-file-alt menu-icon"></i>
                            <span className="menu-title"><Trans>categories</Trans></span>
                        </Link>
                    </li>
                    <li className={this.isPathActive('/event-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.eventMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('eventMenuOpen')} data-toggle="collapse">
                            <i className="fal fa-calendar-day menu-icon"></i>
                            <span className="menu-title"><Trans>products</Trans></span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.eventMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={(this.isPathActive('/event-list') || this.isPathActive('/event-form')) ? 'nav-link active' : 'nav-link'}
                                        to="/event-list">
                                        <Trans>product_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={(this.isPathActive('/event-form') || this.isPathActive('/event-form')) ? 'nav-link active' : 'nav-link'}
                                        to="/product-form">
                                        <Trans>create_product</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className={this.isPathActive('/service-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.serviceMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('serviceMenuOpen')} data-toggle="collapse">
                            <i className="mdi mdi-newspaper-variant-multiple-outline menu-icon"></i>
                            <span className="menu-title">
                                <Trans>services</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.serviceMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/service-list') ? 'nav-link active' : 'nav-link'}
                                        to="/service-list">
                                        <Trans>service_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={this.isPathActive('/service-form') ? 'nav-link active' : 'nav-link'}
                                          to="/service-form">
                                        <Trans>create_service</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className={this.isPathActive('/sector-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.sectorMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('sectorMenuOpen')} data-toggle="collapse">
                            <i className="mdi mdi-newspaper-variant-multiple-outline menu-icon"></i>
                            <span className="menu-title">
                                <Trans>sectors</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.sectorMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/sector-list') ? 'nav-link active' : 'nav-link'}
                                        to="/sector-list">
                                        <Trans>sector_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={this.isPathActive('/sector-form') ? 'nav-link active' : 'nav-link'}
                                          to="/sector-form">
                                        <Trans>create_sector</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className={this.isPathActive('/blog-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.blogMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('blogMenuOpen')} data-toggle="collapse">
                            <i className="fal fa-calendar-day menu-icon"></i>
                            <span className="menu-title"><Trans>blogs</Trans></span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.blogMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={(this.isPathActive('/blog-list') || this.isPathActive('/blog-list')) ? 'nav-link active' : 'nav-link'}
                                        to="/blog-list">
                                        <Trans>blog_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={(this.isPathActive('/blog-form') || this.isPathActive('/blog-form')) ? 'nav-link active' : 'nav-link'}
                                        to="/blog-form">
                                        <Trans>create_blog</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    
                    {/* <li className={this.isPathActive('/appointment') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.appointmentMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('appointmentMenuOpen')} data-toggle="collapse">
                            <i className="fal fa-calendar-check menu-icon"></i>
                            <span className="menu-title">
                                <Trans>appointments</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.appointmentMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/appointment-list') ? 'nav-link active' : 'nav-link'}
                                        to="/appointment-list">
                                        <Trans>appointment_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/appointment-request-list') ? 'nav-link active' : 'nav-link'}
                                        to="/appointment-request-list">
                                        Randevu Başvuruları
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li> */}
                    {/* <li className={this.isPathActive('/ambasador-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.ambasadorMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('ambasadorMenuOpen')} data-toggle="collapse">
                            <i className="fal fa-calendar-check menu-icon"></i>
                            <span className="menu-title">
                                Başkolonsluk Takvimi
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.ambasadorMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/ambasador-schedule-list') ? 'nav-link active' : 'nav-link'}
                                        to="/ambasador-schedule-list">
                                        Başkolonsluk Takvim Listesi
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/ambasador-schedule-form') ? 'nav-link active' : 'nav-link'}
                                        to="/ambasador-schedule-form">
                                        Başkolonsluk Takvimi Ekle
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/ambasador-schedule-request-all') ? 'nav-link active' : 'nav-link'}
                                        to="/ambasador-schedule-request-all">
                                        Randevu Talep Listesi
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li> */}
                    {/* <li className={(this.isPathActive('/user-') || this.isPathActive('/post-list')) ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.userMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('userMenuOpen')} data-toggle="collapse">
                            <i className="mdi mdi-account-multiple menu-icon"></i>
                            <span className="menu-title">
                                <Trans>users</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.userMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={(this.isPathActive('/user-list') || this.isPathActive('/user-form')) ? 'nav-link active' : 'nav-link'}
                                        to="/user-list">
                                        <Trans>user_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={(this.isPathActive('/user-title-list') || this.isPathActive('/user-title-form')) ? 'nav-link active' : 'nav-link'}
                                        to="/user-title-list">
                                        <Trans>user_title_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={this.isPathActive('/post-list') ? 'nav-link active' : 'nav-link'}
                                          to="/post-list">
                                        Kullanıcı Gönderileri
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li> */}
                    <li className={this.isPathActive('/page-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.pageMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('pageMenuOpen')} data-toggle="collapse">
                            <i className="mdi mdi-newspaper-variant-multiple-outline menu-icon"></i>
                            <span className="menu-title">
                                <Trans>pages</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.pageMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/page-list') ? 'nav-link active' : 'nav-link'}
                                        to="/page-list">
                                        <Trans>page_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={this.isPathActive('/page-form') ? 'nav-link active' : 'nav-link'}
                                          to="/page-form">
                                        <Trans>create_page</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className={this.isPathActive('/slider-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.sliderMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('sliderMenuOpen')} data-toggle="collapse">
                            <i className="mdi mdi-newspaper-variant-multiple-outline menu-icon"></i>
                            <span className="menu-title">
                                <Trans>sliders</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.sliderMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/slider-list') ? 'nav-link active' : 'nav-link'}
                                        to="/slider-list">
                                        <Trans>slider_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={this.isPathActive('/slider-form') ? 'nav-link active' : 'nav-link'}
                                          to="/slider-form">
                                        <Trans>create_slider</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className={this.isPathActive('/counter-form') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/counter-form">
                            <i className="fal fa-file-alt menu-icon"></i>
                            <span className="menu-title"><Trans>counter</Trans></span>
                        </Link>
                    </li>
                    <li className={this.isPathActive('/partner-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.partnerMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('partnerMenuOpen')} data-toggle="collapse">
                            <i className="mdi mdi-newspaper-variant-multiple-outline menu-icon"></i>
                            <span className="menu-title">
                                <Trans>partners</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.partnerMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/partner-list') ? 'nav-link active' : 'nav-link'}
                                        to="/partner-list">
                                        <Trans>partner_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={this.isPathActive('/partner-form') ? 'nav-link active' : 'nav-link'}
                                          to="/partner-form">
                                        <Trans>create_partner</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className={this.isPathActive('/vacancy-') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.vacancyMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                             onClick={() => this.toggleMenuState('vacancyMenuOpen')} data-toggle="collapse">
                            <i className="mdi mdi-newspaper-variant-multiple-outline menu-icon"></i>
                            <span className="menu-title">
                                <Trans>vacancies</Trans>
                            </span>
                            <i className="menu-arrow"></i>
                        </div>
                        <Collapse in={this.state.vacancyMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link
                                        className={this.isPathActive('/vacancy-list') ? 'nav-link active' : 'nav-link'}
                                        to="/vacancy-list">
                                        <Trans>vacancy_list</Trans>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={this.isPathActive('/vacancy-form') ? 'nav-link active' : 'nav-link'}
                                          to="/vacancy-form">
                                        <Trans>create_vacancy</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className={this.isPathActive('/seo-form') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/seo-form">
                            <i className="fal fa-file-alt menu-icon"></i>
                            <span className="menu-title">Seo</span>
                        </Link>
                    </li>
                    <li className={this.isPathActive('/contact-form') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/contact-form">
                            <i className="fal fa-file-alt menu-icon"></i>
                            <span className="menu-title"><Trans>contacts</Trans></span>
                        </Link>
                    </li>
                    <li className={this.isPathActive('/message-list') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/message-list">
                            <i className="fal fa-file-alt menu-icon"></i>
                            <span className="menu-title"><Trans>messages</Trans></span>
                        </Link>
                    </li>
                    {/* <li className={this.isPathActive('/logs') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/logs">
                            <i className="fal fa-file-alt menu-icon"></i>
                            <span className="menu-title">Loglar</span>
                        </Link>
                    </li> */}
                    {/* <li className={this.isPathActive('/ambassador-message') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/ambassador-message">
                            <i className="fal fa-file-alt menu-icon"></i>
                            <span className="menu-title">Büyükelçi Mesajı</span>
                        </Link>
                    </li> */}
                    {/* <li className={this.isPathActive('/tickets') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/tickets">
                            <i className="fal fa-ticket-alt menu-icon"></i>
                            <span className="menu-title">Destek Talepleri</span>
                        </Link>
                    </li> */}
                    {/* <li className={this.isPathActive('/promotions') ? 'nav-item active' : 'nav-item'}>
                        <Link className="nav-link" to="/promotions">
                            <i className="fal fa-mailbox menu-icon"></i>
                            <span className="menu-title">Email Gönder</span>
                        </Link>
                    </li> */}
                </ul>
            </nav>
        );
    }
}

export default withRouter(Sidebar);
