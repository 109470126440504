import React, {Component} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {apiUrl, getData, postData} from "./services/ApiService";
import i18n from '../../../i18n';
import {toast} from "react-toastify";
import {dateDiff} from "./services/DateConvert";
import {Redirect} from "react-router";
import {Link, withRouter} from "react-router-dom";

class Navbar extends Component {
    constructor(prop) {
        super(prop);
        this.state = {
            currentLanguage: localStorage.getItem('current_language') ? localStorage.getItem('current_language') : 'tr',
            redirectToLogin: false,
            notifications: [],
            messageList: [],
            badgeCount: 0,
            userDetail: prop.userDetail
        }
    }

    goToNotification(data, index) {
        switch (data.type) {
            case 'appointment-request':

                break;
            case 'appointment-event-request':

                break;
            case 'event-request':

                break;
            case 'event-comment':

                break;
            case 'post-report':

                break;
            case 'comment-report':

                break;
        }
    }

    dateDiff(date) {
        return dateDiff(new Date(date));
    }

    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }

    toggleRightSidebar() {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }

    // componentDidMount() {
    //     getData('notifications').then(response => {
    //         this.setState({
    //             notifications: response.data
    //         })
    //     }).catch(error => this.handleError(error));
    //     getData('notification/badgeCount').then(response => {
    //         this.setState({
    //             badgeCount: response.data
    //         })
    //     }).catch(error => this.handleError(error));
    //     postData('appeal/list', {
    //         filter: {},
    //         query: {
    //             paging: {
    //                 limit: 10,
    //                 offset: 0,
    //                 fetch: 'EAGER'
    //             }
    //         }
    //     }).then(response => {
    //         this.setState({
    //             isLoading: false,
    //             messageList: response.data.data
    //         });
    //     }).catch(error => this.handleError(error));
    // }

    changeLanguage(language) {
        this.setState({
            currentLanguage: language
        })
        localStorage.setItem('current_language', language);
        const htmlTag = document.getElementsByTagName('html')[0];
        const body = document.getElementsByTagName('body')[0];
        htmlTag.setAttribute('lang', language);
        if (language === 'ae' && !body.hasAttribute('dir')) {
            body.classList.add('rtl');
            body.setAttribute('dir', 'rtl');
        } else if (language !== 'ae' && body.hasAttribute('dir')) {
            body.removeAttribute('dir');
            body.classList.remove('rtl')
        }
        return i18n.changeLanguage(language);
    }

    viewAllNotification() {
        getData('notification/viewAll').then(response => {
            this.setState({
                badgeCount: 0
            });
        });
    }

    viewAllMessage() {

    }

    logOut() {
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
            this.props.history.push('/oauth/login');
        }, 10);
    }

    openTicket(id) {
        this.props.history.push('/ticket-detail/' + id);
    }

    handleError(error) {
        this.setState({isLoading: false});
        if (error.response) {
            if (error.response.status === 401) {
                this.setState({
                    redirectToLogin: true
                });
            } else if (error.response.status === 403) {
                this.setState({
                    redirectToLogin: true
                });
            } else {
                const errorText = error.response.data.uimessage[0].text;
                toast.error(typeof errorText === "string" ? errorText : 'Bilinmeyen hata!');
            }
        } else {
            this.setState({
                redirectToLogin: true
            });
        }
    }

    render() {
        if (this.state.redirectToLogin) {
            return (
                <Redirect to="/oauth/login"/>
            )
        }
        return (
            <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                    <a className="navbar-brand brand-logo-mini align-self-center d-lg-none"
                       onClick={evt => evt.preventDefault()}>
                        <img src={require("../../../assets/images/logo-mini.svg")} alt="logo"/>
                    </a>
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button"
                            onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                        <i className="mdi mdi-menu"></i>
                    </button>
                    {/*<form className="ml-auto search-form d-none d-md-block" action="#">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <input type="search" className="form-control" placeholder="Search Here"/>*/}
                    {/*    </div>*/}
                    {/*</form>*/}
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item  nav-profile border-0 pl-4">
                            {/* <Dropdown onClick={() => this.viewAllNotification()}>
                                <Dropdown.Toggle
                                    className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                                    <i className="mdi mdi-bell-outline"></i>
                                    {this.state.badgeCount > 0 ?
                                        <span className="count bg-success">{this.state.badgeCount}</span> : ''}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="navbar-dropdown preview-list notification-list">
                                    <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center">
                                        {
                                            this.state.badgeCount ?
                                                <p className="mb-0 font-weight-medium float-left">
                                                    {this.state.badgeCount} yeni bildirim var
                                                </p> : ''
                                        }
                                        {
                                            this.state.badgeCount ?
                                                <span
                                                    className="badge badge-pill badge-primary float-right">View all</span> : ''
                                        }
                                    </Dropdown.Item>
                                    {
                                        this.state.notifications.map((data, index) => {
                                            return (
                                                <div>
                                                    <div className="dropdown-divider"></div>
                                                    <Dropdown.Item
                                                        className="dropdown-item preview-item d-flex align-items-center"
                                                        onClick={() => this.goToNotification(data, index)}>
                                                        <div className="preview-thumbnail">
                                                            <i className={'mdi m-auto text-primary' + (data.type === 'report' ? ' mdi-alert' : ' mdi-airballoon')}></i>
                                                        </div>
                                                        <div className="preview-item-content py-2">
                                                            <p className="preview-subject ellipsis font-weight-medium text-dark">
                                                                {data.title_tr}
                                                            </p>
                                                            <p className="font-weight-light small-text">
                                                                {data.body_tr}
                                                            </p>
                                                            <p className="font-weight-light small-text mb-0">
                                                                {this.dateDiff(data.created_date).number}
                                                                <Trans>{this.dateDiff(data.created_date).text}</Trans>
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                </div>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </li>
                        <li className="nav-item  nav-profile border-0">
                            {/* <Dropdown>
                                <Dropdown.Toggle
                                    className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                                    <i className="mdi mdi-email-outline"></i>
                                    <span className="count">7</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="navbar-dropdown preview-list">
                                    {
                                        this.state.messageList.map(data => {
                                            return (
                                                <div>
                                                    <div className="dropdown-divider"></div>
                                                    <Dropdown.Item
                                                        className="dropdown-item preview-item d-flex align-items-center"
                                                        onClick={evt => this.openTicket(data.id)}>
                                                        <div className="preview-thumbnail">
                                                            <img
                                                                src={data.user.picture ? (apiUrl + 'user/' + data.user.picture) : require("../../../assets/images/avatar/" + (data.user.gender ? data.user.gender : 'male') + ".png")}
                                                                alt="profile"
                                                                className="img-sm profile-pic"/>
                                                        </div>
                                                        <div className="preview-item-content flex-grow py-2">
                                                            <p className="preview-subject ellipsis font-weight-medium text-dark">
                                                                {data.user.name} {data.user.surname}
                                                            </p>
                                                            <p className="font-weight-light small-text">
                                                                {data.title}
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="dropdown-divider"></div>
                                    <button className="btn btn-link btn-block">
                                        <Link to={'/tickets'}>Tümünü gör</Link>
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </li>
                        <li className="nav-item  nav-profile border-0">
                            <Dropdown>
                                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                                    <span className="profile-text">
                                        {
                                            this.state.userDetail ?
                                                (this.state.userDetail.name + ' ' + this.state.userDetail.surname) : ''
                                        }
                                    </span>
                                    <img className="img-xs rounded-circle"
                                         src={require("../../../assets/images/main.png")} alt="Profile"/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                                    <Dropdown.Item
                                        className="dropdown-item preview-item d-flex align-items-center border-0"
                                        onClick={() => this.logOut()}>
                                        Çıkış Yap
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                            onClick={this.toggleOffcanvas}>
                        <span className="mdi mdi-menu"></span>
                    </button>
                </div>
            </nav>
        );
    }
}

export default withRouter(Navbar);
