import moment from 'moment';
import React from "react";

let hour;
let minute;
let second;
const momentFormat = 'YYYY-MM-DD HH:mm:ss';

export const getGMT = (date) => date.toTimeString().split(' ')[1];

export const utcToLocalDate = (date) => {
    let timestamp;
    if (typeof date === 'number') {
        timestamp = date;
    } else if (typeof date === 'string') {
        timestamp = new Date(date).getTime();
    } else if (typeof date === Date()) {
        timestamp = date.getTime();
    } else {
        return;
    }
    const utcDate = moment.unix(timestamp / 1000).utc().format(momentFormat);
    const local = moment.utc(utcDate).local().format(momentFormat);
    return new Date(moment.utc(local).local().format(momentFormat));
}

export const localToUtc = (date, dateFormat = false) => {
    let timestamp;
    if (typeof date === 'number') {
        timestamp = date;
    } else if (typeof date === 'string') {
        timestamp = new Date(date).getTime();
    } else if (typeof date === Date()) {
        timestamp = date.getTime();
    } else {
        return;
    }
    return moment.unix(timestamp / 1000).utc().format(momentFormat);
}

export const localDate = (date, dateFormat = false) => {
    return moment.parseZone(moment(date, momentFormat)).local().format(momentFormat);
}

export const localDateTimestamp = (date) => {
    return moment(localDate(date), momentFormat).valueOf();
}

// gelen tarihi utc formatına çeviriyor (tarih ve saat birlikte)
export const utcDate = (date, dateFormat = false) => {
    return moment.parseZone(moment(date, momentFormat)).utc().format(momentFormat);
}

// gelen tarihi utc timestamp formatına çeviriyor (tarih ve saat birlikte)
export const utcDateTimestamp = (date) => {
    return moment(utcDate(date), momentFormat).valueOf();
}

// gelen tarihi yıl-ay-gün şeklinde geri dönüyor (saatsiz)
export const convertDate = (date) => {
    this.year = date.getFullYear();
    if (date.getMonth() < 9) {
        // tslint:disable-next-line:radix
        this.month = '0' + (parseInt(date.getMonth()) + 1).toString();
    } else {
        // tslint:disable-next-line:radix
        this.month = (parseInt(date.getMonth()) + 1).toString();
    }
    if (date.getDate() < 10) {
        this.day = '0' + date.getDate();
    } else {
        this.day = date.getDate();
    }
    return this.fullDate = this.year + '-' + this.month + '-' + this.day;
}

export const convertDateAndTime = (date) => {
    if (date.getHours() < 10) {
        hour = '0' + date.getHours();
    } else {
        hour = date.getHours();
    }
    if (date.getMinutes() < 10) {
        minute = '0' + date.getMinutes();
    } else {
        minute = date.getMinutes();
    }
    if (date.getSeconds() < 10) {
        second = '0' + date.getSeconds();
    } else {
        second = date.getSeconds();
    }
    const dateISO = date.toLocaleString().split(' ');
    return this.fullDate = makeDate(date) + ' ' + dateISO[1];
}

export const makeDate = (date, dateFormat = 'YYYY/MM/DD') => {
    const year = date.getFullYear();
    let month, day, fullDate;
    if (date.getMonth() < 9) {
        month = '0' + (date.getMonth() + 1);
    } else {
        month = date.getMonth() + 1;
    }
    if (date.getDate() < 10) {
        day = '0' + date.getDate();
    } else {
        day = date.getDate();
    }
    switch (dateFormat.toLowerCase()) {
        case 'dd.mm.yyyy':
            fullDate = day + '.' + month + '.' + year;
            break;
        case 'dd-mm-yyyy':
            fullDate = day + '-' + month + '-' + year;
            break;
        case 'yyyy/mm/dd':
            fullDate = year + '/' + month + '/' + day;
            break;
        case 'mm/dd/yyyy':
            fullDate = month + '/' + day + '/' + year;
            break;
        case 'dd/mm/yyyy':
            fullDate = month + '/' + day + '/' + year;
            break;
        case 'D/MM/YYYY':
            fullDate = month + '/' + day + '/' + year;
            break;
        case 'yyyy-mm-dd':
            fullDate = year + '-' + month + '-' + day;
            break;
    }
    return fullDate;
};

export const dateDiff = (date) => {
    const nowDate = localDateTimestamp(new Date());
    const diffDate = utcDateTimestamp(date);
    const diffTime = (nowDate - diffDate) / 1000;
    let number;
    let text;
    if (diffTime > 0 && diffTime < 120) {
        text = 'just_few_minutes';
    } else if (diffTime > 119 && diffTime < 3570) {
        number = Math.round(diffTime / 60);
        text = 'minute_ago';
    } else if (diffTime > 3569 && diffTime < 84600) {
        number = Math.round(diffTime / 3600);
        text = 'hour_ago';
    } else if (diffTime > 84599 && diffTime < 648000) {
        number = Math.round(diffTime / 86400);
        text = 'day_ago';
    } else {
        number = Math.round(diffTime / 604800);
        text = 'week_ago';
    }
    return {
        number: number,
        text: text
    }
}
