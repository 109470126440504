import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

require("dotenv").config();

// import FroalaEditorComponent from 'react-froala-wysiwyg';
// ReactDOM.render(<FroalaEditorComponent tag='textarea'/>, document.getElementById('editor'));

ReactDOM.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
